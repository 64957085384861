import {
  IconBolt,
  IconDeviceDesktop,
  IconChartBar,
  IconChartHistogram,
  IconChartPie,
  IconTable,
  IconSettingsAutomation
} from '@tabler/icons';

const icons = {
  IconBolt,
  IconDeviceDesktop,
  IconChartBar,
  IconChartHistogram,
  IconChartPie,
  IconTable,
  IconSettingsAutomation
};

const Pages = (devices, expandedDevice, handleExpand) => {
  if (!devices) {
    return [];
  }

  const menuItems = {
    id: 'pages',
    title: 'Datapoints',
    type: 'group',
    children: devices.map((device) => ({
      id: `dispositivo-${device.DeviceCode}`,
      title: `DIIOT - ${device.DeviceCode}`,
      type: 'collapse',
      icon: icons.IconBolt,
      children:
        expandedDevice === device.DeviceCode
          ? [
              /*
              {
                id: `monitor-${device.DeviceCode}`,
                title: 'Monitor',
                type: 'item',
                icon: icons.IconDeviceDesktop,
                url: `/monitor/${device.DeviceCode}`,
                target: true
              },
              */
              {
                id: `electr-param-${device.DeviceCode}`,
                title: (
                  <>
                    Parámetros Eléctricos <i>(próximamente)</i>
                  </>
                ),
                type: 'item',
                icon: icons.IconChartBar,
                url: `/`, // `/pages/electr-param/${device.DeviceCode}`,
                breadcrumbs: false
              },
              {
                id: `realtime-${device.DeviceCode}`,
                title: 'Tiempo Real',
                type: 'item',
                icon: icons.IconChartHistogram,
                url: `/pages/realtime/${device.DeviceCode}`,
                breadcrumbs: false
              },
              {
                id: `analisishistorico-${device.DeviceCode}`,
                title: 'Análisis Historico',
                type: 'item',
                icon: icons.IconChartPie,
                url: `/pages/analisishistorico/${device.DeviceCode}`,
                breadcrumbs: false
              },
              {
                id: `eventos-${device.DeviceCode}`,
                title: 'Eventos',
                type: 'item',
                icon: icons.IconTable,
                url: `/pages/events/${device.DeviceCode}`,
                breadcrumbs: false
              },
              {
                id: `customEvents-${device.DeviceCode}`,
                title: 'Configuración Eventos',
                type: 'item',
                icon: icons.IconSettingsAutomation,
                url: `/pages/custom-events/${device.DeviceCode}`,
                breadcrumbs: false
              }
            ]
          : [],
      onClick: () => handleExpand(device.DeviceCode)
    }))
  };

  return menuItems;
};

export default Pages;
